.home-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../img/home.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 1025px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

.reservation-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../img/layer20.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

.about-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../img/reservation.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

.contact-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../img/reservation.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

.aszf-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../img/reservation.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

.adatvedelem-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../img/reservation.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

.not-found-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../img/not-found.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

.reservation-header header,
.about-header header,
.contact-header header,
.aszf-header header,
.adatvedelem-header header,
.not-found-header header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 50px 15%;
}

.home-header > header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 130px 15%;
}

#tesla-logo-header {
    position: absolute;
    top: -33rem;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 265px;
}

.container.home-head-btn-ctn {
    position: absolute;
    bottom: 16rem;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn.home-head-btn {
    background-image: url('../img/btn-reservation.png');
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    width: 440px;
    height: 85px;
    padding-top: 24px;
    font-weight: 900;
    font-size: 1.6rem;
}

.container.home-head-ctn {
    text-align: center;
    position: absolute;
    top: 130px;
    color: white;
    left: 50%;
    transform: translate(-50%, -50%);
}

.col.home-head-row1 {
    border-left: 1px solid white;
    align-self: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.col.home-head-row2 {
    border-left: 1px solid white;
    border-right: 1px solid white;
    align-self: center;
}

.col.home-head-row3 {
    border-right: 1px solid white;
    align-self: center;
}

.row.home-head-ctn-row h1 {
    font-size: 2.5rem;
    font-weight: 600;
}

.row.home-head-ctn-row h2 {
    font-size: 2rem;
}

.row.home-head-ctn-row p {
    font-size: 1.1rem;
}

.reservation-header h1,
.about-header h1,
.contact-header h1,
.aszf-header h1,
.adatvedelem-header h1,
.not-found-header h1 {
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 0;
}

.reservation-header h2,
.about-header h2,
.contact-header h2,
.aszf-header h2,
.adatvedelem-header h2,
.not-found-header h2 {
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    font-size: 2.3rem;
    margin-top: 0;
}

.reservation-header p,
.about-header p,
.contact-header p,
.aszf-header p,
.adatvedelem-header p,
.not-found-header p {
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 0;
    margin-top: 40px;
}

@media (max-width: 1200px) {
    .reservation-header,
    .about-header,
    .contact-header,
    .aszf-header,
    .adatvedelem-header,
    .not-found-header {
        width: 100%;
        height: 770px;
        position: relative;
        overflow: hidden;
    }

    .home-header {
        width: 100%;
        height: 770px;
        position: relative;
        overflow: hidden;
    }

    .reservation-header h1,
    .about-header h1,
    .contact-header h1,
    .aszf-header h1,
    .adatvedelem-header h1,
    .not-found-header h1 {
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        font-size: 4rem;
        margin-bottom: 0;
    }

    .reservation-header h2,
    .about-header h2,
    .contact-header h2,
    .aszf-header h2,
    .adatvedelem-header h2,
    .not-found-header h2 {
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        font-size: 2.3rem;
        margin-top: 0;
    }

    #tesla-logo-header {
        position: absolute;
        top: -22rem;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 150px;
    }

    .container.home-head-btn-ctn {
        position: absolute;
        bottom: 13rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn.home-head-btn {
        background-image: url('../img/btn-reservation.png');
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        width: 405px;
        height: 78px;
        padding-top: 23px;
        font-weight: 700;
        font-size: 1.3rem;
    }

    .row.home-head-ctn-row h1 {
        font-size: 2.1rem;
    }

    .row.home-head-ctn-row h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 1024px) {
    .reservation-header,
    .about-header,
    .contact-header,
    .aszf-header,
    .adatvedelem-header,
    .not-found-header {
        width: 100%;
        height: 650px;
        position: relative;
        overflow: hidden;
    }

    .reservation-header h1,
    .about-header h1,
    .contact-header h1,
    .aszf-header h1,
    .adatvedelem-header h1,
    .not-found-header h1 {
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        font-size: 3.5rem;
        margin-bottom: 0;
    }

    .reservation-header h2,
    .about-header h2,
    .contact-header h2,
    .aszf-header h2,
    .adatvedelem-header h2,
    .not-found-header h2 {
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        font-size: 2rem;
        margin-top: 0;
    }

    .reservation-header p,
    .about-header p,
    .contact-header p,
    .aszf-header p,
    .adatvedelem-header p,
    .not-found-header p {
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        font-size: 1.2rem;
        margin-top: 0;
        margin-top: 60px;
    }

    .home-header {
        width: 100%;
        height: 650px;
        position: relative;
        overflow: hidden;
    }

    #tesla-logo-header {
        position: absolute;
        top: -14rem;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 150px;
    }

    .container.home-head-btn-ctn {
        position: absolute;
        bottom: 13rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn.home-head-btn {
        background-image: url('../img/btn-reservation.png');
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        width: 348px;
        height: 67px;
        padding-top: 18px;
        font-weight: 700;
        font-size: 1.2rem;
    }

    .row.home-head-ctn-row h1 {
        font-size: 2.1rem;
    }

    .row.home-head-ctn-row h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .reservation-header,
    .about-header,
    .contact-header,
    .aszf-header,
    .adatvedelem-header,
    .not-found-header {
        width: 100%;
        height: 550px;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .reservation-header h1,
    .about-header h1,
    .contact-header h1,
    .aszf-header h1,
    .adatvedelem-header h1,
    .not-found-header h1 {
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 0;
    }

    .reservation-header h2,
    .about-header h2,
    .contact-header h2,
    .aszf-header h2,
    .adatvedelem-header h2,
    .not-found-header h2 {
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        font-size: 1.4rem;
        margin-top: 0;
    }

    .reservation-header p,
    .about-header p,
    .contact-header p,
    .aszf-header p,
    .adatvedelem-header p,
    .not-found-header p {
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        font-size: 1.1rem;
        margin-top: 0;
        margin-top: 50px;
    }

    .home-header {
        width: 100%;
        height: 550px;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    #tesla-logo-header {
        position: absolute;
        top: -10rem;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100px;
    }

    .container.home-head-btn-ctn {
        position: absolute;
        bottom: 12rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn.home-head-btn {
        background-image: url('../img/btn-reservation.png');
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        width: 300px;
        height: 58px;
        padding-top: 18px;
        font-weight: 700;
        font-size: 1rem;
    }

    .col.home-head-row1 {
        border-left: 1px solid transparent;
        align-self: center;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .col.home-head-row3 {
        border-right: 1px solid transparent;
        align-self: center;
    }

    .row.home-head-ctn-row h1 {
        font-size: 1.5rem;
    }

    .row.home-head-ctn-row h2 {
        font-size: 1.4rem;
    }
}

@media (max-width: 480px) {
    .reservation-header,
    .about-header,
    .contact-header,
    .aszf-header,
    .adatvedelem-header,
    .not-found-header {
        width: 100%;
        height: 420px;
        position: relative;
        overflow: hidden;
    }

    .reservation-header h1,
    .about-header h1,
    .contact-header h1,
    .aszf-header h1,
    .adatvedelem-header h1,
    .not-found-header h1 {
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        font-size: 1.3rem;
        margin-bottom: 0;
    }

    .reservation-header h2,
    .about-header h2,
    .contact-header h2,
    .aszf-header h2,
    .adatvedelem-header h2,
    .not-found-header h2 {
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        font-size: 1rem;
        margin-top: 0;
    }

    .home-header {
        width: 100%;
        height: 420px;
        position: relative;
        overflow: hidden;
    }

    #tesla-logo-header {
        position: absolute;
        top: -5rem;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 80px;
    }

    .container.home-head-btn-ctn {
        position: absolute;
        bottom: 11rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn.home-head-btn {
        background-image: url('../img/btn-reservation.png');
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        width: 300px;
        height: 58px;
        padding-top: 18px;
        font-weight: 700;
        font-size: 1rem;
    }

    .row.home-head-ctn-row h1 {
        font-size: 1rem;
    }

    .row.home-head-ctn-row h2 {
        font-size: 1rem;
    }
}

@media (max-width: 320px) {
    .btn.home-head-btn {
        background-image: url('../img/btn-reservation.png');
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        width: 260px;
        height: 50px;
        padding-top: 15px;
        font-weight: 700;
        font-size: 1rem;
    }

    .container.home-head-btn-ctn {
        position: absolute;
        bottom: 9.3rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

* {
    box-sizing: border-box;
}
